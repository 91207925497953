import * as React from "react"
import {graphql, useStaticQuery} from "gatsby";
import InnerLayout from "./../../components/global/innerLayout"
import parse from "html-react-parser";
import PageTitle from "../../components/global/pageTitle";

const Page = () => {

    const data = useStaticQuery(graphql`
        {
          craft {
            entry(section: "freeStoriesEmailNewsletterSubmissionConfirmation") {
              ... on Craft_freeStoriesEmailNewsletterSubmissionConfirmation_freeStoriesEmailNewsletterSubmissionConfirmation_Entry {
                seomatic {
                  metaTitleContainer
                  metaTagContainer
                  metaLinkContainer
                  metaScriptContainer
                  metaJsonLdContainer
                  metaSiteVarsContainer
                  frontendTemplateContainer
                }
                id
                title
                uri
                pageTitle
                siteBasicBody
              }
            }
          }
        }
  `)

    return (
        <InnerLayout seomatic={data.craft.entry.seomatic}>
            <div className="midd">
                <PageTitle title={data.craft.entry.pageTitle} />

                <div className="content-page">
                    <div className="container-small">
                        <div className="content-colum-row">
                            <div className="content">
                                {parse(data.craft.entry.siteBasicBody)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </InnerLayout>
    )
}

export default Page
